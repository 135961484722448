import {history} from "../../../history"
import axios from "axios"
import jwtService from '../../../services/jwtService/jwtService';

export const LOCK_USER = "[LOGIN] LOGIN LOCK USER";
export const UNLOCK_USER = "[LOGIN] LOGIN_UNLOCK_USER";
export const SET_USER_DATA = "[LOGIN] SET USER DATA";
export const LOGIN_WITH_JWT = "[LOGIN] LOGIN WITH JWT";
export const LOGOUT_WITH_JWT = "[LOGIN] LOGOUT WITH JWT";
export const SET_LOADING = "[LOGIN] SET LOADING";

export const logout = () => {
    return () => {
        jwtService.logout().finally(() => {
            history.push("/login")
        });
    }
}

export const isLoggedIn = () => {
    return dispatch => {
        axios
            .get("/isLoggedIn")
            .then(response => {
                let usr = JSON.parse(localStorage.getItem("user"));
                dispatch({
                    type: LOGIN_WITH_JWT,
                    payload: {usr, loggedInWith: "jwt"}
                })
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export const setUserData = (data) => {
    return (dispatch) => {
        if (data) {
            if (localStorage.getItem("user"))
                localStorage.removeItem("user");
            localStorage.setItem("user", JSON.stringify(data));
            dispatch({
                type: SET_USER_DATA,
                payload: data
            });
            return Promise.resolve();
        }
    };
}

// const setSession = (user, access_token) => {
//   if (localStorage.getItem("user"))
//     localStorage.removeItem("user");
//   localStorage.setItem("user", JSON.stringify(user));
//   if (access_token) {
//     localStorage.setItem('jwt_access_token', access_token);
//     let newDateObj = moment(Date.now()).add(2, 'H').unix();
//     localStorage.setItem('jwt_access_token_time', newDateObj);
//   } else {
//     localStorage.removeItem('jwt_access_token');
//     localStorage.removeItem('jwt_access_token_time');
//   }
// };
