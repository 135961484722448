import {combineReducers} from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/index"

const rootReducer = asyncReducers => combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  ...asyncReducers
})

export default rootReducer
