import axios from "axios";
import moment from "moment";
import EventEmitter from "./EventEmitter";

const {apiUrl} = window["runConfig"];
axios.defaults.baseURL = apiUrl;

axios.interceptors.request.use(
    config => {
        config.headers["x-access-token"] = localStorage.getItem("jwt_access_token") ?? "";
        return config;
    })

class jwtService extends EventEmitter {
    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors = () => {
        let loadingCounter = 0;

        axios.interceptors.request.use(
            (config) => {
                if (loadingCounter === 0) {
                    this.emit("loading", true);
                }
                loadingCounter++;
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (err) => {
                return new Promise((resolve, reject) => {
                    loadingCounter--;
                    if (loadingCounter === 0) {
                        this.emit("loading", false);
                    }
                    if (err.response !== undefined && err.response.status !== undefined) {
                        if (
                            err.response.status === 401 &&
                            err.config &&
                            !err.config.__isRetryRequest
                        ) {
                            this.emit("onAutoLogout"); //Invalid access_token
                            this.logout();
                        } else if (
                            err.response.status === 403 &&
                            err.config &&
                            !err.config.__isRetryRequest
                        ) {
                            this.emit("permissionDenied", "Permission Denied"); //Invalid access_token
                        }
                    }
                    reject(err);
                });
            }
        );
    };

    handleAuthentication = () => {
        let access_token = this.getAccessToken();

        if (!access_token) {
            this.emit("onNoAccessToken");
            return;
        }

        if (this.isAuthTokenValid(access_token)) {
            this.setSession(access_token);
            this.emit("onAutoLogin", true);
        } else {
            this.setSession(null);
            this.emit("onAutoLogout", "access_token expired");
        }
    };

    createUser = (data) => {
        return new Promise((resolve, reject) => {
            axios.post("/api/auth/ChangePassword", data).then((response) => {
                if (response.data.user) {
                    this.setSession(response.data.access_token);
                    resolve(response.data.user);
                } else {
                    reject(response.data.error);
                }
            });
        });
    };

    signInWithEmailAndPassword = (user) => {
        return new Promise((resolve, reject) => {
            axios.post("/login", {
                email: user.email,
                password: user.password,
                rememberme: user.rememberme
            })
                .then((response) => {
                    if (response.data) {
                        let user = response.data.user;
                        if (window.localStorage.getItem("user")) localStorage.removeItem("user");
                        this.setSession(response.data.token);
                        if (response.data.permissions) {
                            const permissions = response.data.permissions;
                            if (localStorage.getItem("permissions"))
                                localStorage.removeItem("permissions");
                            localStorage.setItem("permissions", JSON.stringify(permissions));
                            user.permissions = permissions;
                            const user_str = JSON.stringify(user);
                            localStorage.setItem("user", user_str);
                            resolve(user);
                        } else {
                            reject(response.error);
                        }
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    signInWithToken = () => {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem("user"))
                resolve(JSON.parse(localStorage.getItem("user")));
            else this.logout();
        });
    };

    setSession = (access_token) => {
        if (access_token) {
            localStorage.setItem("jwt_access_token", access_token);
            let newDateObj = moment(Date.now()).add(2, "H").unix();
            localStorage.setItem("jwt_access_token_time", newDateObj);
            axios.defaults.headers.common["x-access-token"] = access_token;
        } else {
            localStorage.removeItem("jwt_access_token");
            localStorage.removeItem("jwt_access_token_time");
            delete axios.defaults.headers.common["x-access-token"];
        }
    };

    logout = () => {
        if (this.getAccessToken()) {
            const request = axios.get("/logout");
            return request.then((response) => {
                if (response.status !== 200) {
                    this.emit('onLogoutFailed', response);
                }
            }).catch((err) => {
                this.emit('onLogoutFailed');
            }).finally(() => {
                this.setSession(null);
                localStorage.removeItem("user");
                localStorage.removeItem("jwt_access_token_time");
                localStorage.removeItem("permissions");
                localStorage.removeItem("defaultPath");
            });
        } else {
            this.setSession(null);
            localStorage.removeItem("user");
            localStorage.removeItem("jwt_access_token_time");
            localStorage.removeItem("permissions");
            localStorage.removeItem("defaultPath");
            return new Promise(resolve => resolve());
        }
    };

    isAuthTokenValid = (access_token) => {
        if (!access_token) {
            return false;
        } else return true;
    };

    getAccessToken = () => {
        return window.localStorage.getItem("jwt_access_token");
    };

    checkChangePassword = () => {
        const permissions = JSON.parse(localStorage.getItem("permissions"));
        if (permissions) {
            for (let i = 0; i < permissions.length; i++) {
                let permission = permissions[i];
                if (permission === "CHANGE_PASSWORD") {
                    return true;
                }
            }
        }
        return false;
    };

    checkSimultaneousLogin = (cb) => {
        const permissions = JSON.parse(localStorage.getItem("permissions"));
        let retval = false;
        this.getSessionsCount((data) => {
            if (data) {
                if (permissions && data) {
                    for (let i = 0; i < permissions.length; i++) {
                        let permission = permissions[i];
                        if (permission !== "GENERAL_LOGIN_MULTI" && data > 1) {
                            retval = true;
                        }
                    }
                }
            }
        });
        return cb(retval);
    };

    checkDate() {
        let date = parseInt(localStorage.getItem("jwt_access_token_time"));
        let now = moment().unix();
        return now > date;
    }

    getSessionsCount(cb) {
        let userid = JSON.parse(localStorage.getItem("user")).data.userid;
        if (localStorage.getItem("sessions")) {
            return cb(parseInt(localStorage.getItem("sessions")));
        } else {
            new Promise((resolve, reject) => {
                axios.get(`/sessions?userid=${userid}`).then((response) => {
                    if (response.data) {
                        const sessions = response.data;
                        if (sessions) {
                            if (localStorage.getItem("sessions"))
                                localStorage.removeItem("sessions");
                            localStorage.setItem("sessions", sessions.length);
                            return cb(sessions.length);
                        }
                    } else {
                        reject(response.error);
                        return cb(null);
                    }
                });
            });
        }
    }
}

export default new jwtService();
