import React, {useEffect} from "react"
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle
} from "reactstrap"
import * as Icon from "react-feather"
import {useDispatch, useSelector} from "react-redux";
import {logout, setUserData} from "../../../redux/actions/auth";
import {history} from "../../../history"

const UserDropdown = () => {
    const dispatch = useDispatch()

    return (
        <DropdownMenu right>
            <DropdownItem tag="a" onClick={() => {
                history.push("/user/edit/" + JSON.parse(localStorage.getItem("user"))._id);
            }}>
                <Icon.Settings size={14} className="mr-50"/>
                <span className="align-middle">Profil</span>
            </DropdownItem>
            <DropdownItem divider/>
            <DropdownItem
                tag="a"
                onClick={() => dispatch(logout())}
            >
                <Icon.Power size={14} className="mr-50"/>
                <span className="align-middle">Çıkış</span>
            </DropdownItem>
        </DropdownMenu>
    )
}

function NavbarUser() {

    const currentUser = useSelector((state) => state.auth.login.currentUser)

    return (
        <ul className="nav navbar-nav navbar-nav-user float-right">
            <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                    <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {currentUser.name}
              </span>
                        <span className="user-status">{currentUser.role}</span>
                    </div>
                    <span data-tour="user">
              {currentUser.currentUser && currentUser.img ? <img
                  src={"data:image/" + currentUser.img.fileType + ";base64," + (currentUser.img.base64Data ?? "")}
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
              /> : <Icon.User width={40}/>
              }
            </span>
                </DropdownToggle>
                <UserDropdown/>
            </UncontrolledDropdown>
        </ul>
    )
}

export default NavbarUser
