import React from "react"
import * as Icon from "react-feather"

const navigationConfig = [
    {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        icon: <Icon.Grid size={20} />,
        navLink: "/",
        permissions: ["showDashboard"]
    },
    {
        id: "appointments",
        title: "Randevular",
        type: "item",
        icon: <Icon.Bell size={20} />,
        navLink: "/appointments/list",
        permissions: ["readAppointment"]
    },
    {
        id: "rooms",
        title: "Oda Yönetimi",
        type: "item",
        icon: <Icon.Crosshair size={20} />,
        navLink: "/rooms/list",
        permissions: ["editRoom"]
    },

    {
        id: "consultants",
        title: "Terapistler",
        type: "item",
        // icon: <Icon.Mail size={20}/>,
        icon: <Icon.Users size={20} />,
        navLink: "/therapists/list",
        permissions: ["editTherapist"]
    },
    {
        id: "customers",
        title: "Danışan Yönetimi",
        type: "item",
        // icon: <Icon.Mail size={20}/>,
        icon: <Icon.UserCheck size={20} />,
        navLink: "/customer/list",
        permissions: ["showCustomer"]
    },
    {
        id: "blogs",
        title: "Blog",
        type: "collapse",
        icon: <Icon.AlignCenter size={20} />,
        children: [
            {
                id: "blogsList",
                title: "Yazılar",
                type: "item",
                icon: <Icon.BookOpen size={20} />,
                navLink: "/blogs/list",
                permissions: ['readPost']
            },
            {
                id: "categoryManagement",
                title: "Kategoriler",
                type: "item",
                icon: <Icon.CheckCircle size={20} />,
                permissions: ["readPost"],
                navLink: "/blogs/categories"
            }
        ]

    },
    {
        id: "cms",
        title: "CMS",
        type: "collapse",
        icon: <Icon.AlignCenter size={20} />,
        children: [
            {
                id: "expertises",
                title: "Uzmanlıklarımız",
                type: "item",
                navLink: "/expertise/list",
                permissions: ["readExpertise"]
            },
            {
                id: "sliders",
                title: "Slaytlar",
                type: "item",
                navLink: "/sliders/list",
                permissions: ["readSlider"]
            },
            {
                id: "contents",
                title: "İçerikler",
                type: "item",
                navLink: "/contents/list",
                permissions: ["readContent"]
            },
            {
                id: "contact",
                title: "İletişim Bilgileri",
                type: "item",
                navLink: "/contacts/list",
                permissions: ["readContact"]
            },
            {
                id: "socialMedias",
                title: "Sosyal Medya",
                type: "item",
                navLink: "/social-media/list",
                permissions: ["readSocialMedia"]
            },
            {
                id: "banners",
                title: "Banner Yönetimi",
                type: "item",
                navLink: "/banners/list",
                // permissions: ["readBanner"]
            }
        ]
    },
    {
        id: "goals",
        title: "Hedefler",
        type: "item",
        icon: <Icon.Flag size={20} />,
        navLink: "/goals/list",
        permissions: ["createNewGoal"]
    },
    {
        id: "hashtags",
        title: "Hashtags",
        type: "item",
        icon: <Icon.Hash size={20} />,
        navLink: "/hashtags/list",
        // permissions: ["createNewGoal"]
    },
    {
        id: "payments",
        title: "Ödemeler",
        type: "item",
        icon: <Icon.Crosshair size={20} />,
        navLink: "/payments/list",
        permissions: ["editRoom"]
    },
    {
        id: "user",
        title: { translate: "sideMenu.usermanagement" },
        type: "item",
        icon: <Icon.User size={20} />,
        navLink: "/user/list",
        permissions: ["createNewUser"]
    },
    {
        id: "authorization",
        title: { translate: "sideMenu.authorization" },
        type: "collapse",
        icon: <Icon.Users size={16} />,
        permissions: ["createPermission", "createRole"],
        children: [
            {
                id: "roleManagement",
                title: { translate: "sideMenu.rolemanagement" },
                type: "item",
                icon: <Icon.List size={20} />,
                permissions: ["createRole"],
                navLink: "/roleManagement"
            },
            {
                id: "permissionManagement",
                title: { translate: "sideMenu.permissionmanagement" },
                type: "item",
                icon: <Icon.Figma size={20} />,
                permissions: ["createPermission"],
                navLink: "/permissionManagement"
            },
        ]
    }
]

export default navigationConfig
